import React, { useRef, useEffect, useState } from 'react'
import MegaphoneIcon from 'assets/images/svg/MegaphoneIcon.svg'
import ColumnsChart from 'components/Charts/ColumnsChart'
import DonutChart from 'components/Charts/DonutChart'
import { useReactToPrint } from 'react-to-print'
import Iconclock from 'assets/images/svg/image-clock.svg'
import IconSucess from 'assets/images/svg/dashboard-success.svg'
import IconDoc from 'assets/images/svg/dashboard-doc.svg'
import { Button, Dropdown, Modal, Form, Input, DatePicker } from 'antd'
import NakhonRatchasima from 'core/utils/NakhonRatchasima_municipality.json'
import { checkColorStatusPoint, checkColorStatusLegend, checkColorStatusLegendNewText } from './container'
import IconImport from 'assets/images/svg/icon-import-complaint.svg'
import IconArrowDown from 'assets/images/svg/icon-arrow-down.svg'
import {
  TitleName,
  BoxbgChart,
  CardGrid,
  BoxOverAll,
  HeaderBox,
  HeaderTopic,
  BoxForm,
  BoxAllState,
  MonthText,
  StyledImage,
  BoxNumOfComp,
  LeftBoxNumOfComp,
  TopicHeaderStatus,
  TextSatus,
  TextSatusStay,
  FlexAgency,
  NumStyle,
  BoxPointMap,
  BoxLegendChart,
  BoxTitle,
  Menucustom,
  CustomTooltip,
} from './style'
import axios from 'axios'
import MapCirCle from 'components/OlHeatMap/MapCircle'
import ReportPdfDashboardAdminStat from './reportPdf'
import moment from 'moment'
import 'moment/locale/th'
import EmtyData from 'assets/images/svg/Search Empty.svg'
import styled from 'styled-components'
import { checkColorDonut } from 'pages/DashboardStatus/container'
moment.locale('th')

const { RangePicker } = DatePicker
const DashboardOverviewAdmin = () => {
  const token = localStorage?.getItem('access_token')
  const [isPrint, setIsPrint] = useState(false)
  const ReportRef = useRef(null)
  const [dataDashboard, setDataDashboard] = useState([])
  const [donutSeries, setDonutSeries] = useState([])
  const [donutXaxis, setDonutXaxis] = useState([])
  const [donutLabel, setDonutLabel] = useState([])
  const [CheckDonutColor, setCheckDonutColor] = useState([])
  const [searchData, setSearchData] = useState({
    date: [moment(new Date()).subtract(1, 'months'), moment(new Date())?.endOf('day')],
  })
  const [isDropdownOpen, setIsDropdownOpen] = useState(false)

  const [cateGoryData, setcateGoryData] = useState({
    dataCategory: [],
    xaxisCategory: [],
  })
  const [departMentData, setdepartMentData] = useState({
    dataDepartMent: [],
    xaxisDepartMent: [],
  })
  const [ratingData, setratingData] = useState({
    seriesChartMany: [],
    xaxisChartMany: [],
  })
  const [CatrateData, setCatrateData] = useState({
    seriesChartCatRate: [],
    xaxisChartCatRate: [],
  })

  const [dataMap, setDataMap] = useState([])
  const [Nodata, setNodata] = useState(false)
  const [meData, setMedata] = useState([])
  const handlePrint = useReactToPrint({
    content: () => ReportRef.current,
    documentTitle: `reportdash`,
    onAfterPrint: () => setIsPrint(false),
  })

  useEffect(() => {
    getDataDashboard()
    GetMeData()
  }, [searchData])

  const GetMeData = async () => {
    try {
      const shemadata = `${process.env.REACT_APP_BASEURL}/admin-authen/api/v1/admin/me`
      const resMeData = await axios.get(`${shemadata}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      if (resMeData.status === 200 || resMeData.status === 201) {
        setMedata(resMeData?.data?.data)
      }
    } catch (error) {
      console.error(error)
    }
  }

  const Donutlabels = [
    'รับเรื่อง',
    'อยู่ระหว่างดำเนินการ',
    'ดำเนินการแล้วเสร็จ',
    'ติดตาม',
    'ไม่อยู่ในความรับผิดชอบ',
    'เกินระยะเวลาที่กำหนด',
  ]

  const getDataDashboard = async paramDate => {
    setNodata(false)
    try {
      const checkStartDate =
        searchData?.date?.length > 0
          ? `${moment(searchData?.date[0]).startOf('day')?.unix()}`
          : moment(new Date()).subtract(1, 'months').unix()
      const checkEndDate =
        searchData?.date?.length > 0
          ? `${moment(searchData?.date[1]).endOf('day')?.unix()}`
          : moment(new Date())?.endOf('day')?.unix()

      const response = await axios.get(
        `${process.env.REACT_APP_BASEURL}/dashboard-report/api/v1/dashboard/overview?start_at=${checkStartDate}&end_at=${checkEndDate}`
      )

      if (response.status === 200 || response.status === 201) {
        const dataAll = response?.data?.data?.dashboard_data
        const dataGraph = dataAll?.graph_data

        const dataSeriesDout = dataGraph?.channel_data?.map(item => item?.total_channel)
        const dataLabelDout = dataGraph?.channel_data?.map(item => item?.channel_name)
        const FilterColorDonut = dataLabelDout.map(item => checkColorStatusLegendNewText(item))

        setCheckDonutColor(FilterColorDonut)

        const dataPercentDout = dataGraph?.channel_data?.map(item => item?.percent)

        const dataCate = dataGraph?.category_data?.map(item => item?.total_day)

        const xaxisCategory = dataGraph?.category_data?.map(item => item?.category_name)

        const dataDepart = dataGraph?.department_data?.map(item => item?.total_day)

        const xaxisDepartMent = dataGraph?.department_data?.map(item => item?.department_name)

        const rainting_data1 = dataGraph?.rating_data?.map(item => item?.rating[0])
        const rainting_data2 = dataGraph?.rating_data?.map(item => item?.rating[1])
        const rainting_data3 = dataGraph?.rating_data?.map(item => item?.rating[2])
        const rainting_data4 = dataGraph?.rating_data?.map(item => item?.rating[3])
        const rainting_data5 = dataGraph?.rating_data?.map(item => item?.rating[4])

        const xaxisChartMany = dataGraph?.rating_data?.map(item => item?.department_name)

        const HeatmapData = dataGraph?.map_data
        setDataMap(HeatmapData)

        const caterate_data1 = dataGraph?.category_rating_data?.map(item => item?.rating[0])
        const caterate_data2 = dataGraph?.category_rating_data?.map(item => item?.rating[1])
        const caterate_data3 = dataGraph?.category_rating_data?.map(item => item?.rating[2])
        const caterate_data4 = dataGraph?.category_rating_data?.map(item => item?.rating[3])
        const caterate_data5 = dataGraph?.category_rating_data?.map(item => item?.rating[4])

        const seriesChartCatRate = [
          {
            name: '1คะแนน',
            data: caterate_data1,
          },
          {
            name: '2 คะแนน',
            data: caterate_data2,
          },
          {
            name: '3 คะแนน',
            data: caterate_data3,
          },
          {
            name: '4 คะแนน',
            data: caterate_data4,
          },
          {
            name: '5 คะแนน',
            data: caterate_data5,
          },
        ]

        const xaxisChartCatRate = dataGraph?.category_rating_data?.map(item => item?.category_name)

        setCatrateData({ ...CatrateData, seriesChartCatRate, xaxisChartCatRate })

        const seriesChartMany = [
          {
            name: '1คะแนน',
            data: rainting_data1,
          },
          {
            name: '2 คะแนน',
            data: rainting_data2,
          },
          {
            name: '3 คะแนน',
            data: rainting_data3,
          },
          {
            name: '4 คะแนน',
            data: rainting_data4,
          },
          {
            name: '5 คะแนน',
            data: rainting_data5,
          },
        ]

        const seriesCategory = [
          {
            name: 'data_category',
            data: dataCate,
          },
        ]
        const seriesDepartment = [
          {
            name: 'data_department',
            data: dataDepart,
          },
        ]

        setratingData({
          ...ratingData,
          seriesChartMany,
          xaxisChartMany,
        })

        setcateGoryData({
          ...cateGoryData,
          dataCategory: seriesCategory,
          xaxisCategory,
        })

        setdepartMentData({
          ...departMentData,
          dataDepartMent: seriesDepartment,
          xaxisDepartMent,
        })

        setDataDashboard(dataAll)
        setDonutSeries(dataSeriesDout)
        setDonutXaxis(dataAll?.graph_data?.channel_data)
        setDonutLabel(dataLabelDout)
      } else {
        setNodata(true)
      }
    } catch (error) {
      setNodata(true)
    }
  }
  const handleChange = (value, type) => {
    if (type === 'input') {
      setSearchData({
        ...searchData,
        search: value?.target?.value ? value?.target?.value : '',
      })
    } else {
      setSearchData({
        ...searchData,
        [type]: value ? value : null,
      })
    }
  }

  const CardDonutPdf = () => {
    return (
      <>
        <div style={{ display: 'flex' }}>
          <div>
            <DonutChart
              labels={donutLabel}
              series={donutSeries}
              // colors={Donutcolors}
              colors={CheckDonutColor}
              legendPosition={'right'}
              width="150px"
              height="150px"
              legendShow
            />
          </div>
          <div>
            {donutXaxis?.map((item, index) => {
              return (
                <BoxLegendChart BgColor={checkColorStatusLegendNewText(item?.channel_name)} key={index}>
                  <div
                    style={{
                      display: 'grid',
                      gridTemplateColumns: '15px 40px 40px 1fr',
                      fontSize: '10px',
                      color: '#717171',
                      marginBottom: '15px',
                      alignItems: 'baseline',
                    }}
                  >
                    <div className="point" />
                    <div style={{ fontSize: '12px', fontWeight: 500, color: '#150F0B' }}>
                      {item?.total_channel?.toLocaleString()}
                    </div>
                    <div>{item?.percent?.toFixed(0) + ' %'}</div>
                    <div> {item?.channel_name}</div>
                  </div>
                </BoxLegendChart>
              )
            })}
          </div>
        </div>
      </>
    )
  }

  const CardColumnPdf = () => {
    return (
      <div style={{ width: '100%' }}>
        <ColumnsChart
          colorChart={'#F36B24'}
          xaxis={departMentData?.xaxisDepartMent}
          series={departMentData?.dataDepartMent}
          width={'700px'}
          height="250px"
          dataLabels={true}
        />
      </div>
    )
  }

  const CardColumnPdfGreen = () => {
    return (
      <div style={{ width: '100%' }}>
        <ColumnsChart
          xaxis={cateGoryData?.xaxisCategory}
          series={cateGoryData?.dataCategory}
          colorChart={'#3AA859'}
          width={'700px'}
          height="250px"
          dataLabels={true}
        />
      </div>
    )
  }

  const CardColumnPdfMany = () => {
    return (
      <div style={{ width: '100%' }}>
        <ColumnsChart
          xaxis={ratingData?.xaxisChartMany}
          series={ratingData?.seriesChartMany}
          width={'700px'}
          height="250px"
        />
      </div>
    )
  }
  const CardColumnPdfManyCategory = () => {
    return (
      <div style={{ width: '100%' }}>
        <ColumnsChart
          xaxis={CatrateData?.xaxisChartCatRate}
          series={CatrateData?.seriesChartCatRate}
          width={'700px'}
          height="250px"
        />
      </div>
    )
  }

  const handleMenuImportClick = e => {
    setIsDropdownOpen(false)
    if (e.key === 'pdf') {
      setIsPrint(true)
      setTimeout(() => {
        handlePrint()
      }, 1000)
    } else if (e.key === 'xlsx') {
      onLoadFile('xlsx')
    } else if (e.key === 'csv') {
      onLoadFile('csv')
    }
  }
  const handleDropdownVisibleChange = visible => {
    setIsDropdownOpen(visible)
  }

  const menuImport = value => {
    return (
      <Menucustom onClick={handleMenuImportClick}>
        <Menucustom.Item key={`pdf`}>PDF</Menucustom.Item>
        <Menucustom.Item key={`xlsx`}>XLSX</Menucustom.Item>
        <Menucustom.Item key={`csv`}>CSV</Menucustom.Item>
      </Menucustom>
    )
  }

  const onLoadFile = async typeFile => {
    try {
      const checkStartDate =
        searchData?.date?.length > 0
          ? `${moment(searchData?.date[0]).startOf('day')?.unix()}`
          : moment(new Date()).subtract(1, 'months').unix()
      const checkEndDate =
        searchData?.date?.length > 0
          ? `${moment(searchData?.date[1]).endOf('day')?.unix()}`
          : moment(new Date())?.endOf('day')?.unix()

      const response = await axios.get(
        `${process.env.REACT_APP_BASEURL}/dashboard-report/api/v1/dashboard/overview/export?start_at=${checkStartDate}&end_at=${checkEndDate}&file_extension=${typeFile}`,
        {
          responseType: 'blob', // Important
        }
      )
      const blob = new Blob([response.data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      })
      const url = window.URL.createObjectURL(blob)
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', `dashboard-overview.${typeFile}`)
      document.body.appendChild(link)
      link.click()
      link.parentNode.removeChild(link)
      window.URL.revokeObjectURL(url)
    } catch (error) {}
  }

  const thaiYear = date => {
    return moment(date).add(543, 'years').format('YYYY')
  }

  const toolTipChart = ({ series, seriesIndex, dataPointIndex, w }) => {
    return `<div class='custom-tooltip' style={{display:'flex',justifyContent:"center",alignContent:"center"}}>
    <div class='custom-ken' style={backgroundColor:"red"}>
    ${w?.globals?.labels[dataPointIndex]} จำนวน : ${series[seriesIndex][dataPointIndex]}
    </div>
    </div>
`
  }

  return (
    <>
      <BoxOverAll>
        <HeaderTopic>
          <div>ภาพรวมสถิติและการติดตามสถานะเรื่องร้องเรียน</div>
          <BoxTitle>
            <div style={{ fontSize: '24px', fontWeight: 500, color: '#19263F' }}></div>
            <Dropdown
              overlay={() => menuImport()}
              trigger={['click']}
              getPopupContainer={triggerNode => triggerNode.parentElement}
              onVisibleChange={handleDropdownVisibleChange}
            >
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  padding: '12px',
                  width: '160px',
                  backgroundColor: '#F36B24',
                  borderRadius: '8px',
                  height: '40px',
                  cursor: 'pointer',
                }}
              >
                <img src={IconImport} alt="add" style={{ display: 'inline' }} />
                <span style={{ paddingLeft: '4px', paddingTop: '1px', fontSize: '16px', color: '#ffffff' }}>
                  ส่งออกข้อมูล
                </span>
                <img
                  src={IconArrowDown}
                  alt="add"
                  style={{
                    display: 'inline',
                    transform: isDropdownOpen ? 'rotate(180deg)' : 'rotate(0deg)',
                    transition: 'transform 0.3s ease',
                  }}
                />
              </div>
            </Dropdown>
            {/* <HeaderBox>
              <Button onClick={handlePrint} className="btn-dowload">
                พิมพ์
              </Button>
            </HeaderBox> */}
          </BoxTitle>
        </HeaderTopic>
        <BoxForm>
          <div style={{ display: 'grid' }}>
            <div>ปีงบประมาณ </div>
            <RangePicker
              width="200px"
              placeholder={['วันที่เริ่มต้น', 'วันที่สิ้นสุด']}
              value={searchData?.date}
              onChange={val => handleChange(val, 'date')}
              format={'DD/MM/YYYY'}
              className="date-picker"
              // disabledDate={disabledDate}
              allowClear={false}
            />
          </div>
        </BoxForm>

        {Nodata ? (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              height: '50vh',
            }}
          >
            <div>
              <img src={EmtyData} alt="emty" />
            </div>
            <div>ไม่พบข้อมูล</div>
          </div>
        ) : (
          <BoxAllState>
            {/* <MonthText>ประจำเดือนกันยายน 2566</MonthText> */}

            <div className="wrap-status-firstline">
              <div className="wrap-box-numofcomp">
                <BoxNumOfComp>
                  <LeftBoxNumOfComp>
                    <StyledImage src={MegaphoneIcon} alt="Megaphone Icon" />
                    <TextSatus>จำนวนผู้ร้องเรียน</TextSatus>
                  </LeftBoxNumOfComp>
                  <NumStyle>{dataDashboard?.total_user?.toLocaleString()}</NumStyle>
                </BoxNumOfComp>
              </div>
              <div className="wrap-box-numofcomp">
                <BoxNumOfComp>
                  <LeftBoxNumOfComp>
                    <StyledImage src={MegaphoneIcon} alt="Megaphone Icon" />
                    <TextSatus>รายการร้องเรียน</TextSatus>
                  </LeftBoxNumOfComp>
                  <NumStyle>{dataDashboard?.total_complaint?.toLocaleString()}</NumStyle>
                </BoxNumOfComp>
              </div>
            </div>

            <div className="wrap-status-secondline">
              <BoxNumOfComp BgNumCom={'#F2FAFF'} className="wrap-box-numofcomp-secondline">
                <LeftBoxNumOfComp>
                  <StyledImage src={Iconclock} alt="Megaphone Icon" />
                  <TextSatus>กำลังดำเนินการ</TextSatus>
                </LeftBoxNumOfComp>
                <NumStyle>{dataDashboard?.total_inprocess_status?.toLocaleString()}</NumStyle>
              </BoxNumOfComp>
              <BoxNumOfComp BgNumCom="#F1FCFB" className="wrap-box-numofcomp-secondline">
                <LeftBoxNumOfComp>
                  <StyledImage src={IconSucess} alt="Megaphone Icon" />
                  <TextSatus>ดำเนินการแล้วเสร็จ</TextSatus>
                </LeftBoxNumOfComp>
                <NumStyle>{dataDashboard?.total_done_status?.toLocaleString()}</NumStyle>
              </BoxNumOfComp>
              <BoxNumOfComp BgNumCom="#F4F8FE" className="wrap-box-numofcomp-secondline">
                <LeftBoxNumOfComp>
                  <StyledImage src={IconDoc} alt="Megaphone Icon" />
                  <TextSatus>ส่งต่อหน่วยงานอื่นๆ</TextSatus>
                </LeftBoxNumOfComp>
                <NumStyle>{dataDashboard?.total_tranfer_status?.toLocaleString()}</NumStyle>
              </BoxNumOfComp>
            </div>
            <CardGrid>
              <BoxbgChart>
                <TitleName>สถิติการร้องเรียนผ่านช่องทางต่างๆ</TitleName>
                <div style={{ display: 'flex', gap: '40px', alignItems: 'center' }}>
                  <DonutChart
                    labels={donutLabel}
                    series={donutSeries}
                    colors={CheckDonutColor}
                    // colors={Donutcolors}
                    width="250px"
                    height="250px"
                    legendShow={'noShow'}
                    legendPosition={'right'}
                  />

                  <div>
                    {donutXaxis?.map((item, index) => {
                      return (
                        <BoxLegendChart BgColor={checkColorStatusLegendNewText(item?.channel_name)} key={index}>
                          <div
                            style={{
                              display: 'grid',
                              gridTemplateColumns: '20px 50px 1fr 1fr',
                              fontSize: '10px',
                              color: '#717171',
                              marginBottom: '15px',
                              alignItems: 'center',
                            }}
                          >
                            <div className="point" />
                            <div style={{ fontSize: '12px', fontWeight: 500, color: '#150F0B' }}>
                              {item?.total_channel?.toLocaleString()}
                            </div>
                            <div>{item?.percent + ' %'}</div>
                            <div> {item?.channel_name}</div>
                          </div>
                        </BoxLegendChart>
                      )
                    })}
                  </div>
                </div>
              </BoxbgChart>
              <BoxbgChart>
                <div style={{ position: 'relative' }}>
                  <div style={{ position: 'absolute', zIndex: 999 }}>
                    แผนที่แสดงความหนาแน่นของเรื่องร้องเรียนตามพื้นที่ <span>(เรื่อง)</span>
                  </div>
                  <div
                    style={{
                      backgroundColor: '#fff',
                      borderRadius: '10px',
                      padding: '10px',
                      position: 'absolute',
                      zIndex: 999,
                      width: '145px',
                      height: '160px',
                      top: '40px',
                      left: '10px',
                    }}
                  >
                    {Donutlabels?.map((item, index) => (
                      <BoxPointMap BgColor={checkColorStatusPoint(item)} key={index}>
                        <div className="point" />
                        <span>{item}</span>
                      </BoxPointMap>
                    ))}
                  </div>
                  {!isPrint && <MapCirCle heatmapData={dataMap} zooms={13} isPrint={isPrint} />}
                </div>
              </BoxbgChart>
            </CardGrid>
            <FlexAgency>
              <TopicHeaderStatus>
                <TextSatus> กราฟแสดงระยะเวลาการดำเนินงานของแต่ละหน่วยงาน</TextSatus>
                <TextSatusStay>(เรื่อง)</TextSatusStay>
              </TopicHeaderStatus>
              <CustomTooltip>
                <ColumnsChart
                  xaxis={departMentData?.xaxisDepartMent}
                  series={departMentData?.dataDepartMent}
                  colorChart={'#F36B24'}
                  toolTipChart={toolTipChart}
                  height="350px"
                  dataLabels={true}
                />
              </CustomTooltip>
            </FlexAgency>
            <FlexAgency>
              <TopicHeaderStatus>
                <TextSatus>กราฟแสดงระยะเวลาแบ่งตามประเภทเรื่องร้องเรียน</TextSatus>
                <TextSatusStay>(เรื่อง)</TextSatusStay>
              </TopicHeaderStatus>

              <CustomTooltip>
                <ColumnsChart
                  xaxis={cateGoryData?.xaxisCategory}
                  series={cateGoryData?.dataCategory}
                  colorChart={'#3AA859'}
                  toolTipChart={toolTipChart}
                  height="350px"
                  dataLabels={true}
                ></ColumnsChart>
              </CustomTooltip>
            </FlexAgency>

            {/* //// */}
            <FlexAgency>
              <TopicHeaderStatus>
                <TextSatus>กราฟแสดงจำนวนสถิติความพึงพอใจของผู้ใช้งาน </TextSatus>
                <TextSatusStay>(เรื่อง)</TextSatusStay>
              </TopicHeaderStatus>
              <CustomTooltip>
                <ColumnsChart
                  toolTipChart={toolTipChart}
                  xaxis={ratingData?.xaxisChartMany}
                  series={ratingData?.seriesChartMany}
                  height="350px"
                ></ColumnsChart>
              </CustomTooltip>
            </FlexAgency>

            <FlexAgency>
              <TopicHeaderStatus style={{ justifyContent: 'space-between' }}>
                <TextSatus>กราฟประเมินความพึงพอใจแต่ละประเภท</TextSatus>
              </TopicHeaderStatus>
              <CustomTooltip>
                <ColumnsChart
                  toolTipChart={toolTipChart}
                  // xaxis={dataMock.map(item => item.name)}
                  // series={dataMock}
                  xaxis={CatrateData.xaxisChartCatRate}
                  series={CatrateData.seriesChartCatRate}
                  height="350px"
                ></ColumnsChart>
              </CustomTooltip>
            </FlexAgency>
          </BoxAllState>
        )}
      </BoxOverAll>

      <ReportPdfDashboardAdminStat
        searchData={searchData}
        meData={meData}
        dataDashboard={dataDashboard}
        ReportRef={ReportRef}
        CardColumnPdfGreen={CardColumnPdfGreen()}
        CardColumnPdf={CardColumnPdf()}
        CardColumnPdfMany={CardColumnPdfMany()}
        CardDonutPdf={CardDonutPdf()}
        CardColumnPdfManyCategory={CardColumnPdfManyCategory()}
        ratingData={ratingData}
        cateGoryData={cateGoryData}
        departMentData={departMentData}
        heatmapData={dataMap}
        Donutlabels={Donutlabels}
        isPrint={isPrint}
      />
    </>
  )
}

export default DashboardOverviewAdmin

const CustomBox = styled.div`
  color: rgb(55, 61, 63);
  font-size: 12px;
  font-weight: 400;
  font-family: Helvetica, Arial, sans-serif;
`

const Boxxx = styled.div`
  height: 12px;
  width: 12px;
  background-color: red;
  margin-right: 5px;
  margin-left: 4px;
  margin-bottom: 2px;
`
